(function() {
    angular.module('EntrakV5').controller('appController', appController);

    function appController($scope, $rootScope, $q, $transitions, $state, Service, KEY, APIKEY, Api, LOGIN_URL, IS_LOCAL) {
        console.log('appController');
        var caller = null;
        //global value
        // $rootScope.langCode
        // $rootScope.currentUser
        // $rootScope.previousState
        $rootScope.currentState = '';
        $rootScope.noLoginStates = ['landing'];
        $rootScope.hideTabs = false;
        $rootScope.loadingPage = 0;
        $rootScope.infoMap = {
            noTenant: {
                name: Service.translate("tenant.noTenant"),
                dns: null
            }
        }
        $rootScope.searchPlaceholder = Service.translate("label.search");
        $rootScope.signInMethod = null;
        $rootScope.idToken = null;
    //global value

    //global constant
        $rootScope.INVITE_STATUS = APIKEY.invite;
        $rootScope.STATUS = APIKEY.status;
        $rootScope.NODE_TYPE = APIKEY.nodeType;
        $rootScope.NODE_TYPE_INV = APIKEY.nodeTypeInv;
        $rootScope.APPLICATION_TYPE = APIKEY.applicationType;
        $rootScope.APPLICATION_TYPE_INV = APIKEY.applicationTypeInv;
        $rootScope.ACL_ROLE = APIKEY.aclRole;
        $rootScope.STEP1 = {
            name: Service.translate("tenant.tenantTab"),
            navigate: function(){
                $state.go("tenant");
            }
        }
    //global constant

    //global function
        $rootScope.haveReadingType = function(applicationType){
            // getEnvironmental
            return applicationType === APIKEY.applicationType.aircon || applicationType === APIKEY.applicationType.iaqSensor || applicationType === APIKEY.applicationType.lightSensor || applicationType === APIKEY.applicationType.thermometer;
        }
        $rootScope.haveHistoryType = function(applicationType){
            // light, switch (getDeviceLog)
            // aircon (getDeviceLog, getIaqInfo)
            // energyMeter (summarykWh removed?)
            // thermometer, iaqSensor (getIaqInfo)
            // lightSensor (getLightSensorHistoricalData)
            return applicationType === APIKEY.applicationType.light || applicationType === APIKEY.applicationType.aircon
                || applicationType === APIKEY.applicationType.iaqSensor || applicationType === APIKEY.applicationType.lightSensor
                || applicationType === APIKEY.applicationType.thermometer || applicationType === APIKEY.applicationType.switch;
                 // || applicationType === APIKEY.applicationType.energyMeter TODOricky
        }
        $rootScope.canOnOffType = function(applicationType){//get
            return applicationType === APIKEY.applicationType.light || applicationType === APIKEY.applicationType.aircon
            || applicationType === APIKEY.applicationType.fan || applicationType === APIKEY.applicationType.switch;
        }
        $rootScope.canBlinkType = function(applicationType){
            return applicationType === APIKEY.applicationType.light;
        }

        $rootScope.loadDeviceLog = function(device, deviceCaller, success, failure, recordCount) {
            recordCount = recordCount || 5;
            var result = {};
            if ($rootScope.canOnOffType(device.applicationType)){
                var now = Service.getUnixTimestamp(new Date());
                deviceCaller.call(Api.getDeviceLog(device.id, now-7*24*3600, now, recordCount, false)).then(function(res){
                    var degree = Service.translate("label.degree");
                    res.forEach(function(r){
                        if ($rootScope.haveReadingType(r.applicationType)){
                            r.value = Service.numFmt(r.temperature, 1) + degree;
                        } else if (r.applicationType === APIKEY.applicationType.light){
                            r.value = r.level + "%";
                        }
                    });
                    result.records = res;
                    success(result);
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    failure(err);
                    setTimeout(function(){
                        alert(Service.translate("error.generalGetDataFail"));
                    }, 400);
                });
            } else if (device.applicationType === APIKEY.applicationType.lightSensor){
                var now = Service.getUnixTimestamp(new Date());
                deviceCaller.call(Api.getLightSensorLog(device.id, now-24*3600, now)).then(function(res){
                    result.records = [];
                    if (res.luxDatas){
                        result.records = res.luxDatas;
                    }
                    success(result);
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    failure(err);
                    setTimeout(function(){
                        alert(Service.translate("error.generalGetDataFail"));
                    }, 400);
                });
            } else if (device.applicationType === APIKEY.applicationType.energyMeter){
                var now = new Date();
                var startDate = new Date(now);
                startDate.setHours(0, 0, 0, 0);
                startDate.setDate(startDate.getDate() - 10);
                startDate = Service.getUnixTimestamp(startDate);
                now = Service.getUnixTimestamp(now);
                deviceCaller.call(Api.getEnergyMeterLog(device.serialId, startDate, now)).then(function(res){
                    res.reverse();
                    result.records = res;
                    success(result);
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    failure(err);
                    setTimeout(function(){
                        alert(Service.translate("error.generalGetDataFail"));
                    }, 400);
                });
            } else {
                success(result);
            }
        }

        $rootScope.getLandlordId = function(){
            return $scope.landlordIdPromise;
        }

        $rootScope.displayRoles = function(user){
            return user?.aclRoles?.map(r => Service.translate("label." + r))?.join(", ");
        }

        $rootScope.hasMinAclRole = function(minRole, user) {
          return Service.hasMinAclRole(user || $rootScope.currentUser, minRole);
        }
        $rootScope.getAclRoleLv = function(role) {
          return Service.getAclRoleLv(role);
        }
        $rootScope.getUserAclRoleLv = function(user) {
          return Service.getUserAclRoleLv(user || $rootScope.currentUser);
        }
        $rootScope.isAclRole = function(role, user) {
          return Service.isAclRole(user || $rootScope.currentUser, role);
        }

        function _processGwForDeviceMap(gw, zoneId) {
            gw.cssClass = '';
            gw.applicationType = APIKEY.applicationType.gateway;
            gw.location = Service.getArrItem(gw.gatewayLocations, zoneId, "zoneId");
        }
        $rootScope.processGwForDeviceMap = function(gateways, zoneId) {
            if (gateways && zoneId) {
                if (Array.isArray(gateways)) {
                    gateways.forEach(gw => _processGwForDeviceMap(gw, zoneId));
                } else {
                  _processGwForDeviceMap(gateways, zoneId);
                }
            }
        }

        //only query floor.gateways/floor.locations when needGateway
        $rootScope.updateTenantStep2Name= function(steps, tenantId, floorId, forceGetFloor, caller, needDevice, needGateway){
            var tenantInfo = $rootScope.infoMap[tenantId];
            var floorInfo = $rootScope.infoMap[floorId];

            if (tenantInfo && floorInfo){
                var buildingInfo = $rootScope.infoMap[floorInfo.buildingId];
                steps[1].name = tenantInfo.name;
                if (buildingInfo) {
                    steps[2].name = buildingInfo.name + " - " + floorInfo.name;  
                    if (!forceGetFloor)
                        return;
                } else {
                  steps[2].name = floorInfo.name;
                }
            }

            var qArray = [];
            qArray.push(tenantInfo ? tenantInfo : Api.getTenant(tenantId));
            if (floorInfo && !forceGetFloor){
                qArray.push(floorInfo);
            } else {
                qArray.push(Api.getFloor(floorId, needDevice, needGateway));
            }
            return caller.call(qArray).then(function(res){
                var buildingId = res[1].buildingId;
                if (typeof res[0] === "object")
                    $rootScope.infoMap[tenantId] = { name: res[0].name, dns: res[0].dns };
                if (typeof res[1] === "object"){
                    $rootScope.infoMap[floorId] = { name: res[1].name, buildingId };
                }
                steps[1].name = $rootScope.infoMap[tenantId].name;
                var bInfo = $rootScope.infoMap[buildingId];
                if (bInfo) {
                  steps[2].name = bInfo.name + " - " + $rootScope.infoMap[floorId].name;
                } else {
                  caller.call(Api.getBuilding(buildingId)).then(r => {
                    $rootScope.infoMap[buildingId] = { name: r.name};
                    steps[2].name = r.name + " - " + $rootScope.infoMap[floorId].name;
                  });
                }
                console.log("res[1]",res[1]);
                return res[1];
            }, function(res){
                throw res;
            });
        }

        $rootScope.breadcrumbNavigate = function($node){
            $node.navigate();
        }

        // confirm/delete window
        $scope.confirmWinOpt = {
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: [],
            deactivate: function(){
                $scope._confirmWin = {};
            }
        }
        $scope._confirmWin = {
            desc: null,
            displayName: null,
            func: null,
            calling: false,
            isDelete: false
        }
        $rootScope.confirmPopup = {
            show: function(titleCode, descCode, displayName, func){
                $scope.confirmWin.title(Service.translate(titleCode));
                $scope._confirmWin = {
                    desc: Service.translate(descCode),
                    displayName: displayName,
                    func: function(){
                        $scope._confirmWin.calling = true;
                        if (func)
                            func();
                    },
                    calling: false,
                    isDelete: false
                }
                setTimeout(function(){
                    $scope.confirmWin.open().center();
                });
            },
            close: function(){
                $scope.confirmWin.close();
            }
        }
        $rootScope.deletePopup = {
            show: function(titleCode, descCode, displayName, func){
                $rootScope.confirmPopup.show(titleCode, descCode, displayName, func);
                $scope._confirmWin.isDelete = true;
            },
            close: $rootScope.confirmPopup.close
        }

        // scan window
        $scope.infoWinOpt = {
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: [],
        }
        $rootScope.infoPopup = {
            show: function(titleCode, descCode, callback){
                $scope.infoWin.title(Service.translate(titleCode));
                $scope._infoWin = {
                    desc: Service.translate(descCode),
                    callback: function(){
                        $rootScope.infoPopup.close();
                        if (callback)
                            callback();
                    },
                }
                setTimeout(function(){
                    $scope.infoWin.open().center();
                });
            },
            close: function(){
                $scope.infoWin.close();
            }
        }
    //global function

    //header
        $scope.selectedTab = null;
        $scope.isSuper = false;

        $scope.langDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("header.en"),
                short: Service.translate("header.enShort"),
                value: 'en'
            }],
            // }, {
            //     text: Service.translate("header.zh"),
            //     short: Service.translate("header.zhShort"),
            //     value: 'zh'
            // }, {
            //     text: Service.translate("header.cn"),
            //     short: Service.translate("header.cnShort"),
            //     value: 'cn'
            // }],
            value: $rootScope.langCode,
            dataTextField: "text",
            dataValueField: "value",
            template: '#=data.text#',
            select: function(e){
                Service.setLangCode(e.dataItem.value);
                window.location.reload();
            }
        }
        $scope.userDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("header.logout"),
                value: 'logout'
            }],
            dataTextField: "text",
            dataValueField: "value",
            valueTemplate: '<div ng-bind="currentUser.firstName"></div>',
            template: '#=data.text#',
            select: function(e){
                if (e.dataItem.value === 'logout'){
                    Api.logout();
                }
            }
        }
        $scope.landlordDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
            change: function(){
                var id = this.value();
                caller.call(Api.changeProfileLandlord(id)).then(function(){
                    if ($rootScope.currentState === 'tenant'){
                        window.location.reload();
                    } else {
                        window.location.href = window.location.href.split($rootScope.currentState)[0] + "tenant";
                        window.location.reload();

                    }
                });
            }
        }
    //header

    //click overlay to close kendo window
        $(document).on('click', '.k-overlay', function() {
            var kendoWindow = $('.k-window-content.k-content', $(this).next('div.k-widget.k-window'));
            if (kendoWindow == null || kendoWindow.length == 0)
                return;
            kendoWindow.data('kendoWindow').close();
        });
    //click overlay to close kendo window

    //transition
        caller = Api.createApiCaller();
        $transitions.onStart({}, function(trans) {
            console.log('start: ' + trans.from().name + " -> " + trans.to().name);
            $rootScope.title = null;
            $rootScope.hideTabs = false;

            var fromState = trans.from().name;
            var toState = trans.to().name;
            var isFirstLoad = ($rootScope.noLoginStates.indexOf(fromState) != -1 || fromState === '');

            // if $rootScope.currentUser obj is not set yet, skip checking now and do redirect in getCurrentUser callback
            if ((toState == 'distributor' || toState == 'timetable' || toState == 'distributorDetail' || toState == 'hub' || toState == 'city') && $rootScope.noLoginStates.indexOf(fromState) == -1 && $rootScope.currentUser){
                if (!$scope.isSuper){
                    console.error('only entrak can access', toState);
                    return false;
                }
            }

            $rootScope.loadingPage = 0;
            if (isFirstLoad && $rootScope.noLoginStates.indexOf(toState) == -1){
                $scope.loadingUser = true;
                if (IS_LOCAL) {
                  var m = Service.storageGet("et_method");
                  var t = Service.storageGet("et_it");
                } else {
                  var m = Service.getCookie("et_method");
                  var t = Service.getCookie("et_it");
                }
                if (m) {
                  $rootScope.signInMethod = m;
                  $rootScope.idToken = t;
                }
                if (!$rootScope.signInMethod)
                  window.location = LOGIN_URL;
                Api.initApolloClient($rootScope.signInMethod, $rootScope.idToken, true);
                if (!caller)
                  caller = Api.createApiCaller();
                $scope.landlordIdPromise = caller.call(Api.getCurrentUser()).then(function(res){
                    $rootScope.currentUser = res;

                    $scope.isSuper = $rootScope.isAclRole(APIKEY.aclRole.super, res);
                    if (!$rootScope.hasMinAclRole(APIKEY.aclRole.landlordUser, res) || !res.landlordId){
                        $scope.loadingUser = false;
                        alert("Sorry, you don't have permission to access landlord page. Please login a landlord account and try again.");
                        Api.logout();
                        return;
                    } else if ($scope.isSuper){
                        caller.call(Api.getLandlords()).then(function(landlords){
                            var ddList = $("#landlordDropdownId").data("kendoDropDownList");
                            ddList.setDataSource(new kendo.data.DataSource({ data: landlords }));
                            ddList.value(res.landlordId)
                            $scope.loadingUser = false;
                        }, function(err){
                            $scope.loadingUser = false;
                            alert(Service.translate("error.generalGetDataFail"));
                        });
                    } else {
                        $scope.loadingUser = false;
                    }

                    if (!$scope.isSuper && ($rootScope.currentState == 'distributor' || $rootScope.currentState == 'distributorDetail' || $rootScope.currentState == 'hub' || $rootScope.currentState == 'city' || $rootScope.currentState == 'timetable')){
                        console.error('redirecting to dashboard, only admin can access ' + $rootScope.currentState);
                        $state.go('tenant');
                    }
                    return res.landlordId;
                }, function(err){
                    $scope.loadingUser = false;
                    alert(Service.translate("error.generalGetDataFail"));
                });
            }
        });

    		$transitions.onSuccess({}, function(trans) {
    			console.log('success: ' + trans.from().name + " -> " + trans.to().name);
          $rootScope.previousState = $rootScope.currentState;
    			$rootScope.currentState = trans.to().name;
          if ($rootScope.currentState === 'landlordProfile'){
              $scope.selectedTab = 'profile';
          } else {
              $scope.selectedTab = $rootScope.currentState;
          }
    		});
    //transition

        $scope.$on('$destroy', function() {
            console.log("appController destroy");
        });
    }
})();
