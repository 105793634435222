import moment from 'moment';
import 'moment-timezone';

(function() {
    'use strict';

    angular.module('EntrakV5').service('Service', ['$rootScope', '$http', 'KEY', 'APIKEY', 'LANG', 'MS_CONSENT', 'URL', 'COOKIE_PREFIX', service]);

    function service($rootScope, $http, KEY, APIKEY, LANG, MS_CONSENT, URL, COOKIE_PREFIX) {
        console.log('service');

        const landlordRoles = [APIKEY.aclRole.landlordUser, APIKEY.aclRole.landlordAdmin, APIKEY.aclRole.super];
        
        function storageSave(key, value){
            try {
                if (typeof(Storage)){
                    localStorage.setItem(key, value);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageDelete(key){
            try {
                if (typeof(Storage)){
                    localStorage.removeItem(key);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageGet(key){
            try {
                if (typeof(Storage))
                    return localStorage[key];
            } catch(e) {
                console.error(e);
            }
        }

        function updateLangCode(langCode, dontSave){
            if (langCode === KEY.zh){
                var kendoLangCode = "zh-TW";
            } else if (langCode === KEY.cn){
                var kendoLangCode = "zh-CN";
            } else {
                langCode = KEY.en;
                var kendoLangCode = "en-US";
            }

            if (!dontSave && typeof(Storage))
                localStorage.setItem("appLanguage", langCode);
            $rootScope.langCode = langCode;
            kendo.culture(kendoLangCode);
        }

        function _getDate(unixTimestamp){
            return new Date(unixTimestamp*1000);
        }

        function _getUnixTimestamp(dateOrTimestamp){
            if (dateOrTimestamp instanceof Date){
                return Math.round(dateOrTimestamp.getTime() / 1000);
            } else {
                return dateOrTimestamp;
            }
        }

        function _getTimezoneDiffInMilliseconds(tzString) {
            var tmp = new Date();
            tmp = [tmp.getFullYear(), tmp.getMonth(), tmp.getDate()];
            return (moment.tz(tmp, moment.tz.guess()).unix() - moment.tz(tmp, tzString).unix()) * 1000;
            // var date = tmp.toLocaleDateString("en-UK", {timeZone: tzString}).split("/");
            // var time = tmp.toLocaleTimeString("en-UK", {timeZone: tzString}).split(":");
            // var foreignDate = new Date(tmp);
            // foreignDate.setDate(parseInt(date[0], 10));
            // foreignDate.setMonth(parseInt(date[1], 10) - 1);
            // foreignDate.setFullYear(parseInt(date[2], 10));
            // foreignDate.setHours(parseInt(time[0], 10));
            // foreignDate.setMinutes(parseInt(time[1], 10));
            // foreignDate.setSeconds(parseInt(time[2], 10));
            // return foreignDate.getTime() - tmp.getTime();
        }

        function _getDayDiff(date1, date2){
                if (date1 == null){
                    console.warn("getDayDiff date1:", date1);
                    return null;
                }

                date1 = typeof date1 === 'object' ? new Date(date1) : _getDate(date1);
                date1.setHours(0, 0, 0, 0);
                if (date2 == null){
                    date2 = new Date();
                } else {
                    date2 = typeof date2 === 'object' ? new Date(date2) : _getDate(date2);
                }
                date2.setHours(0, 0, 0, 0);

                return (date1.getTime() - date2.getTime()) / 86400000;
            }

        function _timeToPercentOfDay(unixTimestamp){
            var tmp = _getDate(unixTimestamp);
            return (tmp.getSeconds() + tmp.getMinutes()*60 + tmp.getHours()*3600) / 864;// =x/3600/24*100
        }


        function _getAclRoleLv(role) {
          return landlordRoles.indexOf(role);
        }

        function _getUserAclRoleLv(user) {
          if (user?.aclRoles) {
            for (let i = landlordRoles.length - 1; i >= 0; i--) {
              if (user.aclRoles.indexOf(landlordRoles[i]) != -1) {
                return i;
              }
            }
          }
          return -1;
        }

        function _hasMinAclRole(user, minRole) {
          if (!user?.aclRoles || !minRole) {
            return false;
          }
          const requiredLv = _getAclRoleLv(minRole);
          const userLv = _getUserAclRoleLv(user);
          if (requiredLv == -1 || userLv == -1) {
            return false;
          } else {
            return userLv >= requiredLv;
          }
        }

        function _isAclRole(user, role) {
          if (user?.aclRoles) {
            for (let r = 0; r < user.aclRoles.length; r++) {
              if (role === user.aclRoles[r]) {
                return true;
              }
            }
          }
          return false;
        }

        return {

            getMsConsentLink: function(msTenantId){
                return MS_CONSENT.replace("${tenantId}", msTenantId);
            },

            getDevicesCenter: function(devices){
                if (!devices || devices.length == 0)
                    return null;

                var x1 = Number.MAX_VALUE;
                var y1 = Number.MAX_VALUE;
                var x2 = -1;
                var y2 = -1;

                for (var i=0; i<devices.length; i++){
                    var loc = devices[i].location;
                    if (loc){
                        if (loc.x < x1)
                            x1 = loc.x;
                        if (loc.y < y1)
                            y1 = loc.y;
                        if (loc.x > x2)
                            x2 = loc.x;
                        if (loc.y > y2)
                            y2 = loc.y;
                    }
                }

                return {
                    x: (x1 + x2) / 2,
                    y: (y1 + y2) / 2
                }
            },

            getImgDataUrl: function(url, callback){
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = function() {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    callback(canvas.toDataURL('image/png'));
                }
                img.src = url;
            },

            withinSchedule: function(node){
                return node.is_within_schedule;
            },

            arrayToMap: function(arr, key, map){
                if (!key)
                    key = 'id';
                if (!map)
                    map = {};
                if (arr){
                    for (var i=0; i<arr.length; i++){
                        map[arr[i][key]] = arr[i];
                    }
                }

                return map;
            },

            //by id
            replaceArrItem: function(arr, items, addIfNotFound){
                if (!Array.isArray(items))
                    items = [items];

                for (var i=0; i<items.length; i++){
                    var j=0;
                    for (; j<arr.length; j++){
                        if (arr[j].id == items[i].id){
                            arr.splice(j, 1, items[i]);
                            break;
                        }
                    }
                    if (addIfNotFound && j == arr.length)
                        arr.push(items[i]);
                }
            },

            //by id
            deleteArrItem: function(arr, itemOrId, keyName){
                if (keyName == null)
                    keyName = "id";
                if (itemOrId != null && typeof itemOrId === 'object')
                    itemOrId = itemOrId[keyName];

                for (var j=0; j<arr.length; j++){
                    if (arr[j][keyName] == itemOrId){
                        return arr.splice(j, 1)[0];
                        break;
                    }
                }
            },

            //default by id
            getArrItem: function(arr, val, keyName){
                if (!keyName)
                    keyName = "id";
                for (var j=0; j<arr.length; j++){
                    if (arr[j][keyName] == val)
                        return arr[j];
                }
                return null;
            },

            //default field: "name"
            getSorter: function(field){
                if (!field)
                    field = "name";
                return function(a, b){
                    if (a[field] > b[field]){
                        return 1;
                    } else if (a[field] < b[field]){
                        return -1;
                    } else {
                        return 0;
                    }
                }
            },
            getSorterIgnoreCase: function(field){
                if (!field)
                    field = "name";
                return function(a, b){
                    return (a[field] || '').toLowerCase().localeCompare((b[field] || '').toLowerCase());
                }
            },

            getMinuteDiff: function(date1, date2){
                if (date1 == null){
                    console.warn("getMinuteDiff date1:", date1);
                    return null;
                }

                date1 = _getUnixTimestamp(date1);
                if (!date2){
                    date2 = _getUnixTimestamp(new Date());
                } else {
                    date2 = _getUnixTimestamp(date2);
                }

                return (date1 - date2) / 60;
            },

            //in local timezone
            getDayDiff: _getDayDiff,

            sortSameDayTimeslot: function(timeslots){
                if (timeslots){
                    timeslots.sort(function(a, b){
                        if (a.start.hour == b.start.hour){
                            return a.start.minute - b.start.minute;
                        } else {
                            return a.start.hour - b.start.hour;
                        }
                    });
                }
            },

            timeslotTimeToStr: function(timeslotTime){
                return (timeslotTime.hour < 10 ? "0" : "") + timeslotTime.hour + (timeslotTime.minute < 10 ? ":0" : ":") + timeslotTime.minute;
            },

            dateFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "D";    //ddd, d MMM yyyy
                } else if (fmt === "short"){
                    fmt = "m";    //d MMM
                } else if (!fmt){
                    fmt = "D";    //ddd, d MMM yyyy
                }
                return kendo.toString(date, fmt);
            },
            timeFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "T";    //h:mmtt
                } else if (fmt === "short"){
                    fmt = "t";    //htt
                } else if (!fmt){
                    fmt = "T";    //h:mmtt
                }
                return kendo.toString(date, fmt);
            },
            datetimeFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = _getDate(date);
                
                if (fmt === "long") {
                    fmt = "F";    //h:mmtt, d MMM yyyy
                } else if (fmt === "short") {
                    fmt = "L";    //h:mmtt, d MMM
                } else if (!fmt){
                    fmt = "F";    //h:mmtt, d MMM yyyy
                }
                return kendo.toString(date, fmt);
            },

            getDate: _getDate,

            getUnixTimestamp: _getUnixTimestamp,

            getTimezoneDiffInMilliseconds: _getTimezoneDiffInMilliseconds,

            getLocalDatetime: function(tzString, foreignHour, foreignMinute, now) {
                var tmp = typeof now === 'number' ? _getDate(now) : new Date(now);
                tmp.setSeconds(0);
                tmp.setMilliseconds(0);
                var tzDiff = _getTimezoneDiffInMilliseconds(tzString);
                tmp.setHours(foreignHour);
                tmp.setMinutes(foreignMinute);
                return new Date(tmp.getTime() - tzDiff);
            },

            numFmt: function(val, decimal){
                if (decimal && decimal > 0){
                    var fmt = "#.";
                    for (var i=0; i<decimal; i++)
                        fmt += '0';
                    return kendo.toString(val, fmt);
                } else if (val < 1) {
                    return kendo.toString(val, "#.##");
                } else if (val < 10) {
                    return kendo.toString(val, "#.#");
                } else {
                    return kendo.toString(val, "##,#");
                }
            },

            timeToPercentOfDay: _timeToPercentOfDay,

            //onlineLog{time, online}, statusLog{time, status}
            processStatusLog: function(onlineLog, statusLog, displayStartTime, logDurationInDay, statusLogIsDescending){
              var dayLength = 24 * 3600;
              if (!onlineLog)
                onlineLog = [];

              //get previous online
              var prevOnline = null;
              for (var i=0; i<onlineLog.length; i++) {
                if (onlineLog[i].time >= displayStartTime){//should have least one record from the first date(startTime date) but not guaranteed
                  prevOnline = onlineLog;
                  onlineLog = prevOnline.splice(i);
                  if (i == 0) {//only have selected range record
                    prevOnline = !onlineLog[0].online;
                    console.error("unexpected error, no previous record found");
                  } else {//have previous and selected range record
                    prevOnline = prevOnline[prevOnline.length-1].online;
                  }
                  break;
                }
              }
              if (prevOnline === null) {
                if (onlineLog.length){//only have previous record
                  prevOnline = onlineLog[onlineLog.length-1].online;
                } else {//no record
                  prevOnline = false;
                  console.error("unexpected error, no previous record found, assume previous status is offline");
                }
                onlineLog = [];
              }

              //init oneline barData
              var displayEndTime = _getUnixTimestamp(new Date());
              if (_getDayDiff(displayEndTime, displayStartTime) + 1 > logDurationInDay)
                displayEndTime = displayStartTime + logDurationInDay * 24 * 3600 - 1;
              var displayOnlineLog = onlineLog.slice().reverse();
              var barData = {
                  days: [],
              }
              for (var i=0; i<logDurationInDay; i++){
                  var d = displayStartTime + i*dayLength;
                  barData.days.push(d);
                  barData[d] = [];
              }

              //create dummy data the for loop can loop all data
              var start = {
                  time: displayStartTime,
                  online: prevOnline,
              }
              onlineLog.push({
                  time: displayEndTime,
                  online: onlineLog.length ? !onlineLog[onlineLog.length-1].online : !prevOnline
              });

              //create online bar data (on/offline only)
              var tmpOnlineBars = [];//1d copy of the bar data (online only) for next step to use
              for (var i=0; i<onlineLog.length; i++){
                  //may have consecutive on/offline data, find the changing point (start & onlineLog[i])
                  if (onlineLog[i].online != start.online){
                      var barStart = _timeToPercentOfDay(start.time);
                      var barEnd = _timeToPercentOfDay(onlineLog[i].time);
                      var dayDiff = _getDayDiff(onlineLog[i].time, start.time);
                      var curDayStart = displayStartTime + Math.floor((start.time - displayStartTime) / dayLength) * dayLength;
                      if (dayDiff == 0){
                        var tmp = {
                          l: barStart+"%",
                          w: barEnd-barStart+"%",
                          sTime: start.time,
                          eTime: onlineLog[i].time,
                          online: start.online,
                        }
                        barData[curDayStart].push(tmp);
                        if (start.online)
                          tmpOnlineBars.push(tmp);
                      } else {
                        var tmp = {
                          l: barStart+"%",
                          w: 100-barStart+"%",
                          sTime: start.time,
                          eTime: curDayStart + dayLength - 1,
                          online: start.online,
                        }
                        barData[curDayStart].push(tmp);
                        if (start.online)
                          tmpOnlineBars.push(tmp);
                        for (var d=1; d<dayDiff; d++){
                          var tmpDayStart = curDayStart+d*dayLength;
                          tmp = {
                            l: "0%",
                            w: "100%",
                            sTime: tmpDayStart,
                            eTime: tmpDayStart + dayLength - 1,
                            online: start.online,
                          }
                          barData[tmpDayStart].push(tmp);
                          if (start.online)
                            tmpOnlineBars.push(tmp);
                        }
                        var tmpDayStart = curDayStart+dayDiff*dayLength;
                        tmp = {
                          l: "0%",
                          w: barEnd+"%",
                          sTime: tmpDayStart,
                          eTime: onlineLog[i].time,
                          online: start.online,
                        }
                        barData[tmpDayStart].push(tmp);
                        if (start.online)
                          tmpOnlineBars.push(tmp);
                      }
                      start = onlineLog[i];
                  }
              }

              //handle status
              var displayStatusLog = null;
              if (statusLog){
                //process device status log
                if (!statusLogIsDescending)
                  statusLog.reverse();
                var prevStatus = false;
                displayStatusLog = [];
                for (var i=statusLog.length-1; i>=0; i--){
                  if (statusLog[i].time >= displayStartTime) {
                    displayStatusLog = statusLog.slice(0, i+1);
                    prevStatus = statusLog[i+1];
                    if (prevStatus) {
                      prevStatus = prevStatus.status;
                    } else {
                      prevStatus = !statusLog[i].status;
                      console.error("unexpected error, no previous record found");
                    }
                    break;
                  }
                }

                //add on/off activities into barData
                var tmpStatusLogs = displayStatusLog.slice();
                tmpOnlineBars.forEach(function(oBar){
                  oBar.statusList = [];
                  while (tmpStatusLogs.length) {
                    var slog = tmpStatusLogs[tmpStatusLogs.length-1];
                    if (slog.time < oBar.sTime) {
                      prevStatus = tmpStatusLogs.pop().status;
                    } else if (slog.time >= oBar.eTime){
                      break;
                    } else {//within online range
                      if (oBar.statusList.length == 0) {
                        oBar.statusList.push({
                          isDummy: true,
                          time: oBar.sTime,
                          status: prevStatus,
                        });
                      }
                      oBar.statusList.push(tmpStatusLogs.pop());
                      prevStatus = slog.status;
                    }
                  }
                  if (oBar.statusList.length == 0){
                    oBar.statusList.push({
                      isDummy: true,
                      time: oBar.sTime,
                      status: prevStatus,
                    });
                  }
                  oBar.statusList.push({
                    isDummy: true,
                    time: oBar.eTime,
                    status: !oBar.statusList[oBar.statusList.length-1].status,
                  });
                });

                //convert on/off activities into bars
                tmpOnlineBars.forEach(function(oBar){
                  oBar.statusBars = [];
                  for (var i=0; i<oBar.statusList.length; i++){//there must be a at least 2 records
                    for (var j=i+1; j<oBar.statusList.length; j++){
                      var startStatus = oBar.statusList[i];
                      var endStatus = oBar.statusList[j];
                      if (startStatus.status != endStatus.status){
                        var w = oBar.eTime - oBar.sTime;
                        oBar.statusBars.push({
                          l: 100 * (startStatus.time - oBar.sTime) / w + "%",
                          w: 100 * (endStatus.time - startStatus.time) / w + "%",
                          sTime: startStatus.time,
                          eTime: endStatus.time,
                          status: startStatus.status,
                        });
                        i = j-1;
                        break;
                      }
                    }
                  }
                });
              }

              return {
                displayOnlineLog: displayOnlineLog,
                displayStatusLog: displayStatusLog,
                barData: barData
              }
            },

            translate: function(key, params){
                if (!key)
                    return '';
                
                var arr = key.trim().split(".");
                var node = LANG[$rootScope.langCode];
                for (var i=0; i<arr.length; i++){
                    node = node[arr[i]];
                    if (node == null)
                        return '';
                }

                if (params){
                    for (var key in params){
                        if (params.hasOwnProperty(key)){
                            node = node.replace("${" + key + "}", params[key]);
                        }
                    }
                }
                return node;
            },

            initLangCode: function(){
              if (typeof(Storage) && localStorage.appLanguage) {
                  updateLangCode(localStorage.appLanguage, true);
              } else {
                  if (window.navigator.language){
                      var langCode = window.navigator.language.split("-")[0];
                  } else if (window.navigator.userLanguage){
                      var langCode = window.navigator.userLanguage.split("-")[0];
                  } else if (window.navigator.browserLanguage){
                      var langCode = window.navigator.browserLanguage.split("-")[0];
                  } else if (window.navigator.systemLanguage){
                      var langCode = window.navigator.systemLanguage.split("-")[0];
                  }
                  updateLangCode(langCode);
              }
            },
            setLangCode: function(langCode){
                updateLangCode(langCode);
            },

            formPost: function (endpoint, dataObj) {
                const arr = Object.keys(dataObj || {});
                return fetch(URL + endpoint, {
                  method: "POST",
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                  credentials: "include",
                  body: arr.map(k => `${k}=${encodeURIComponent(dataObj[k])}`).join("&")
                });
            },
            parseJson: function(response) {
                if (response.ok) {
                  return response.json();
                } else {
                  console.log('fetch not ok');
                  throw response;
                }
            },

            storageSave: storageSave,
            storageGet: storageGet,
            storageDelete: storageDelete,

            getCookie: function(key) {
              let name = COOKIE_PREFIX + key + "=";
              let cookies = decodeURIComponent(document.cookie).split(';');
              for(let i=0; i<cookies.length; i++) {
                let c = cookies[i].trimStart();
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            },

            setCookie: function(key, value, expiryDays) {
              const d = new Date();

              d.setTime(d.getTime() + ((expiryDays || 30) * 24 * 3600 * 1000));
              let expires = "expires=" + d.toUTCString();
              document.cookie = COOKIE_PREFIX + key + "=" + value + ";" + expires + ";domain=.en-trak.com;path=/";
            },

            deleteCookie: function(key) {
              document.cookie = COOKIE_PREFIX + key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.en-trak.com;path=/";
            },


            hasMinAclRole: _hasMinAclRole,

            getAclRoleLv: _getAclRoleLv,

            getUserAclRoleLv: _getUserAclRoleLv,

            isAclRole: _isAclRole,
        }
    }

})();
