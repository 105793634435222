import './app.less';
import './common.less';

(function() {
    'use strict';

    angular.module('EntrakV5', ['ui.router', 'kendo.directives', 'ngDraggable', 'ngClipboard']).run(['Service', '$state', runBlock]);

    function runBlock(Service, $state){
        console.log('run');

        Service.initLangCode();
		Service.setLangCode("en");//TODOricky
		
    }
}())