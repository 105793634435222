(function() {
    'use strict';

    angular.module('EntrakV5').constant('KEY', {
        en: "en",
        zh: "zh",
        cn: "cn",
        ignore: "IGNORE",
        accessTokenName: "appAccessToken",
        refreshTokenName: "appRefreshToken",
        scrollBarWidth: (function(){
            var outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.overflow = "scroll";
            outer.style.width = "100px";
            document.body.appendChild(outer);

            var inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            var width = outer.offsetWidth - inner.offsetWidth;
            outer.parentNode.removeChild(outer);

            return width;
        })(),
        recentControlListLength: 6,
        volumeLvMax: 10,
        minBeforeShowExtend: 30,
        timerDefault: 30,
        timerStep: 5,
        defaultTimerMaxHr: 2,
        defaultTimerExtendMin: 30,
        scalePerZoom: 1.3,
        zoomMax: 2,
        zoomMin: 0.2,
        nodeStructure: [    //values must be the same in APIKEY.nodeType
            ["COMPANY"],
            ["FLOOR"],
            // ["ZONE"],
            ["WORKSTATION", "ROOM"],// "CORRIDOR" maybe need to add when multiple corridor
        ],
        chartFontColor: "#4a4a4a",
        chartBorderColor: "#9b9b9b",
        chartGreenColor: "#5cbc0d",
        chartOrangeColor: "#f09800",
        chartRedColor: "#d0021b",
    }).constant('APIKEY', {
        allOn: "ALL_ON",
        allOff: "ALL_OFF",
        mixOnOff: "MIX",
        fanSpeed: {
            high: "high",
            medium: "medium",
            low: "low",
            auto: "auto",
        },
        provider: {
            google: "GOOGLE",
            microsoft: "MICROSOFT",
            email: "EMAIL",
        },
        weatherProvider: {
            hk: "HK_OBSERVATORY",
            sg: "SG_OBSERVATORY",
            open: "OPEN_WEATHER_MAP"
        },
        aclRole: {
            unknown: "UNKNOWN",
            super: "SUPERUSER",
            landlordUser: "LANDLORD_USER",
            landlordAdmin: "LANDLORD_ADMIN",
            tenantUser: "TENANT_USER",
            tenantAdmin: "TENANT_ADMIN",
            zoneAdmin: "ZONE_ADMIN",
            dashboardAdmin: "DASHBOARD_ADMIN"
        },
        invite: {
            pending: "PENDING",
            active: "ACTIVE",
        },
        action: {
            checkin: "CHECKIN",
            checkout: "CHECKOUT",
            extend: "EXTEND",
            warmer: "WARMER",
            cooler: "COOLER",
            normal: "NORMAL",
            blink: "BLINK",
            on: "ON",
            off: "OFF",
        },
        status: {
            warming: "WARMING",
            cooling: "COOLING",
            error: "ERROR",
            on: "ON",
            off: "OFF",
            inprogress: "INPROGRESS",
        },
        applicationType: {    //must sync with Inv
            light: "LIGHT",
            aircon: "AIRCON",
            button: "BUTTON",
            gateway: "GATEWAY",
            iaqSensor: "IAQ_SENSOR",
            lightSensor: "LIGHT_SENSOR",
            motionSensor: "OCCUPANCY_SENSOR",
            switch: "SWITCH",
            door: "DOOR",
            fan: "FAF",
            energyMeter: "ENERGY_METER",
            thermometer: "THERMOMETER",
            unknown: "UNKNOWN",
        },
        applicationTypeInv: {
            LIGHT: "light",
            AIRCON: "aircon",
            BUTTON: "button",
            GATEWAY: "gateway",
            IAQ_SENSOR: "iaqSensor",
            LIGHT_SENSOR: "lightSensor",
            OCCUPANCY_SENSOR: "motionSensor",
            SWITCH: "switch",
            DOOR: "door",
            FAF: "fan",
            ENERGY_METER: "energyMeter",
            THERMOMETER: "thermometer",
            UNKNOWN: "unknown",
        },
        deviceTypeList: [    //must sync with Inv
          "Thermostat",
          "VariableAirVolume",
          "Button",
          "OccupancySensor",
          "Relay",
          "LightSensor",
          "VariableRefrigerantVolume",
          "IAQ",
          "Smartlight",
          "EnergyMeter",
          "Door",
        ],
        deviceProviderList: [
          "ENTRAK",
          "OWON",
          "BACNET",
          "PHILIPS",
          "SMARTLIGHT",
          "DYNALITE",
          "COOLINK",
          "MODBUS",
          "DUMMY",
          "NANO",
          "DFOCUS",
          "EMS",
          "SIGNIFY",
          "VERGESENSE",
        ],
        nodeType: {    //must sync with Inv
            company: "COMPANY",
            floor: "FLOOR",
            // zone: "ZONE",
            room: "ROOM",
            workstation: "WORKSTATION",
            corridor: "CORRIDOR",
        },
        nodeTypeInv: {
            COMPANY: "company",
            FLOOR: "floor",
            // ZONE: "zone",
            ROOM: "room",
            WORKSTATION: "workstation",
            CORRIDOR: "corridor",
        },
        monday: "monday",
        tuesday: "tuesday",
        wednesday: "wednesday",
        thursday: "thursday",
        friday: "friday",
        saturday: "saturday",
        sunday: "sunday",
        dayKey: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
    });
})();
