(function() {
    'use strict';

    angular.module('EntrakV5').directive('translate', ['Service', '$compile', function(Service, $compile) {
	    return {
	    	restrict: "A",
	    	scope: {
	    		translate: "@",
	    		transParams: "=",
	    	},
			link: function(scope, elem, attr){
				if (scope.translate){
					scope.$watch('translate', function(n){
						elem.html(Service.translate(n, scope.transParams));
					});
				}
				if (scope.transParams){
					var key = elem.text();
					scope.$watch('transParams', function(n){
						elem.html(Service.translate(scope.translate ? scope.translate : key, n));
					});
				}
				if (!scope.translate && !scope.transParams)
					elem.html(Service.translate(elem.text(), scope.transParams));
			}
	    }
	}]).directive('etScrolled', [function() {
		return {
			restrict: "A",
			scope: {
				etScrolled: "&",
			},
			link: function(scope, element, attrs) {
				scope.scrolled = function(){
					scope.etScrolled();
				}
				element.on('scroll', scope.scrolled);
				scope.$on('$destroy', function() {
					element.off('scroll', scope.scrolled);
				});
			}
		}
    }]).directive("etBreadcrumb", ['$compile', function($compile){
		return {
			restrict: "E",
			scope: {
				etModel: "=",	// array of obj
				etNavigate: "&",	//function(...., $node, $i)
				etBack: "&",		//function(...., $node, $i)
				etNameField: "@",	//default: name
				etReadOnly: "=",
			},
			link: function(scope, element, attrs){
				element.addClass("etBreadcrumb");

				if (!scope.etNameField)
					scope.etNameField = "name";

				scope.navigate = function($i, $node){
					scope.etModel.splice($i + 1);
					scope.etNavigate({$i: $i, $node: $node});
				}
				scope.back = function(){
					scope.etModel.pop();
					scope.etBack({$i: scope.etModel.length-1, $node: scope.etModel[scope.etModel.length-1]});
				}
				var html = '<button ng-if="etModel.length > 1 && !etReadOnly" ng-click="back()" class="backBtn"></button>'
					+ '<div ng-repeat="node in etModel" ng-class="{leafNode: $last, rootNode: $first, readOnly: etReadOnly}">'
					+ '<span ng-bind="node.' + scope.etNameField + '" ng-click="($last || etReadOnly) ? false : navigate($index, node)"></span>'
					+ '</div>';
				var elm = $compile(html)(scope);
				element.append(elm);
			}
		}
	}]).directive('etVolume', ['$compile', 'KEY', 'APIKEY', function($compile, KEY, APIKEY) {
	    return {
	    	restrict: "E",
	    	scope: {
	    		etChange: "&",	//function($value)
	    		etModel: "=",	//need an object for 2-way binding to work, percentage(etMinValue-100)
	    		etModelField: "@",	//default name: dimmingLevel
	    		etApplicationType: "@",	//default: LIGHT
	    		etDisabled: "=",	//default: false
	    		etLevel: "@",	//num of steps, default 5
	    		etAllowZero: "@",	//min value is zero or not
	    		etMinHeightPercent: "@"	//height for the shortest bar, percent of the highest one, default 40%
	    	},
			link: function(scope, element, attrs){
				element.addClass("etVolume");

				if (!scope.etModelField)
					scope.etModelField = "dimmingLevel";
				if (!scope.etLevel || scope.etLevel < 2)
					scope.etLevel = KEY.volumeLvMax;
				var stepPercent = 100 / scope.etLevel;
				var minPercent = scope.etAllowZero ? 0 : stepPercent;
				var iconType = APIKEY.applicationTypeInv[scope.etApplicationType];
				if (!iconType)
					iconType = APIKEY.applicationTypeInv.LIGHT;

				if (!scope.etMinHeightPercent || scope.etMinHeightPercent < 0  || scope.etMinHeightPercent > 100)
					scope.etMinHeightPercent = 40;
				var heightStep = (100 - scope.etMinHeightPercent) / (scope.etLevel - 1);

				scope._etClick = function(value){
					if (scope.etDisabled || value == scope.etModel[scope.etModelField])
						return;

					scope.etModel[scope.etModelField] = value;
					if (typeof scope.etChange === 'function')
						scope.etChange({$value: scope.etModel[scope.etModelField]});
				}

				scope.$watch("etModel[etModelField]", function(n){
					if (n > 100 || n < stepPercent){
						console.error("etVolume invalid model value:", n);
						scope.etModel[scope.etModelField] = stepPercent;
					}
				});

				var html = "";
				for (var i=0; i<scope.etLevel; i++){
					if (i != 0)
						html = '<div></div>' + html;
					html = '<div class="volumeBar" ng-class="{on: etModel[etModelField] >= ' + (100 - i*stepPercent) + ', disabled: etDisabled}" ng-click="_etClick(' + (100 - i*stepPercent) + ')" style="height: ' + (100 - i*heightStep) + '%;"></div>' + html;
				}
				html += '<div class="applicationType ' + iconType + '"></div>';
				var elm = $compile(html)(scope);
				element.append(elm);
			}
		}
    }]).directive("etUploadImg", ['$compile', '$timeout', 'Service', function($compile, $timeout, Service){
        return {
            restrict: "E",
            scope: {
            	// etModel: {
            	// 	init(return): func
            	//	callback: func
            	// 	resultStr(return): base64 string
            	//	fileObject(return): file
            	//	fileName(return/init): string
            	// }
              etShowBoxOnTop: "=",
              etBoxHeight: "@",
              etBoxWidth: "@",
              etBtnLabel: "@",
              etInputLabel: "@",
              etInputCls: "@",
            	etModel: "=",
            },
            link: function(scope, element, attrs) {
            	element.addClass("etUploadImg");

              if (!scope.etBtnLabel)
                scope.etBtnLabel = "uploadCtrl.upload";

              scope.boxSize = {};
              if (scope.etBoxHeight)
                scope.boxSize.height = scope.etBoxHeight;
              if (scope.etBoxWidth)
                scope.boxSize.width = scope.etBoxWidth;
              if (scope.etShowBoxOnTop) {
                scope.boxSize["margin-top"] = 0;
                scope.boxSize["margin-bottom"] = "15px";
              }

            	var acceptedFormat = ['jpg', 'jpeg', 'png'];

                scope.init = function(remoteImgPath) {
                	scope.errMsg = null;
                    if (remoteImgPath) {
                        scope.updateDisplayImg(remoteImgPath);
                        scope.etModel.fileObject = null;
                        scope.etModel.resultStr = remoteImgPath;
                        var paths = remoteImgPath.split("/");
                        scope.etModel.fileName = paths[paths.length-1];
                        scope.loadingProgress = 100;
                    } else {
	                    element.find("input:file").val(null);
	                    scope.etModel.fileObject = null;
	                    scope.etModel.resultStr = null;
	                    scope.etModel.fileName = null;
	                    scope.loadingProgress = -1;
                    }
                    if (scope.etModel.callback)
                    	scope.etModel.callback();
                }
                scope.etModel.init = scope.init;

                scope.updateDisplayImg = function(image) {
                    var jImg = element.find(".previewImg");
                    jImg.get(0).src = image;
                }

                var html = scope.etShowBoxOnTop ? (
                  '<div ng-style="etBoxWidth ? {width: etBoxWidth} : null">' +
                    '<div class="previewImgContainer" ng-style="boxSize" ng-class="{loading: loadingProgress != 100 && loadingProgress != -1}">' +
                      '<img ng-show="loadingProgress != -1" class="previewImg" />' +
                      '<span class="{{etInputCls}}" ng-show="etInputLabel && loadingProgress == -1" translate>' + scope.etInputLabel + '</span>' +
                    '</div>' +
                    '<div class="centerBtnContainer">' +
                      '<label class="normalBtn lightColor">' +
                        '<span translate>' + scope.etBtnLabel + '</span>' +
                        '<input type="file">' +
                      '</label>' +
                      '<div class="errorMsg" ng-show="errMsg != null">{{errMsg}}</div>' +
                    '</div>' +
                  '</div>'
                ) : (
                  '<div class="rowStart">' +
                    '<label class="normalBtn lightColor">' +
                      '<span translate>' + scope.etBtnLabel + '</span>' +
                      '<input type="file">' +
                    '</label>' +
                    '<span class="fileName" ng-show="loadingProgress == 100">{{etModel.fileName}}</span>' +
                    '<span class="errorMsg" ng-show="errMsg != null">{{errMsg}}</span>' +
                  '</div>' +
                  '<div ng-show="loadingProgress != -1" class="previewImgContainer" ng-style="boxSize" ng-class="{loading: loadingProgress != 100 && loadingProgress != -1}">' +
                    '<img class="previewImg" />' +
                  '</div>'
                );

                var elm = $compile(html)(scope);
                element.append(elm);

                element.find("input:file").get(0).addEventListener("change", function() {
                    if (this.files && this.files[0]) {
                    	var file = this.files[0];
                    	var fType = file.name.split(".").pop().toLowerCase();
                    	scope.errMsg = null;
                    	if (acceptedFormat.indexOf(fType) == -1){
                    		scope.$apply(function() {
                                scope.errMsg = Service.translate("uploadCtrl.invalidFormat");
                                scope.loadingProgress = -1;
                                scope.etModel.fileName = null;
                                scope.etModel.fileObject = null;
                                scope.etModel.resultStr = null;
			                    if (scope.etModel.callback)
			                    	scope.etModel.callback();
                            });
                    		return;
                    	}

                        var FR = new FileReader();
                        FR.onloadstart = function(event) {
                            scope.$apply(function() {
                                scope.loadingProgress = 0;
                                scope.etModel.fileObject = null;
                                scope.etModel.resultStr = null;
                            });
                        }
                        FR.onprogress = function(event) {
                            if (event.lengthComputable) {
                                scope.$apply(function() {
                                    scope.loadingProgress = event.loaded / event.total * 100;
                                });
                            }
                        }
                        FR.onloadend = function(event) {
                            var error = event.target.error;
                            if (error != null){
                            	if (event.target.result.indexOf("data:image") != 0)
                            		error = Service.translate("uploadCtrl.invalidFormat");
                            }
                            if (error != null) {
                                scope.$apply(function() {
                                	scope.errMsg = error;
                                    scope.loadingProgress = -1;
                                    scope.etModel.fileName = null;
                                    scope.etModel.fileObject = null;
                                    scope.etModel.resultStr = null;
                                });
      			                    if (scope.etModel.callback)
      			                    	scope.etModel.callback();
                            } else {
                                scope.$apply(function() {
                                    scope.updateDisplayImg(event.target.result);
                                    scope.loadingProgress = 100;
                                    scope.etModel.fileName = file.name;
                                    scope.etModel.fileObject = file;
                                    scope.etModel.resultStr = event.target.result;
                                });
                                if (scope.etModel.callback)
                                	scope.etModel.callback();
                            }
                        }
                        FR.readAsDataURL(file);
                    }
                }, false);
            }
        }
    }]).directive("etDeviceMap", ['$compile', 'KEY', 'APIKEY', function($compile, KEY, APIKEY){//no pan, will have x y scrollbar
		return {
			restrict: "E",
			scope: {
				etImageUrl: "@",
				etModel: "=",	//need an object for 2-way binding to work, ( return fields: selectedMarker, zoomLv, zoomValue, isDimMode)
				etMarkerDrop: "&",	//function(...., $i, $event)
				etMarkerHover: "&",	//function(...., $i, $event)
				etMarkerHoverEnd: "&",	  //function(...., $i, $event)
				etMarkerClick: "&",	//function(...., $i, $event)
				etMapClick: "&?",	//function(...., $x, $y, $event)
				etContainerClick: "&",//function(...., $event)
				etMapScrolled: "&",
				etModelField: "@",	//default: markers
				etTypeField: "@",	//default: applicationType
				etDraggable: "=",	//default: false
				etDragStart: "&",	//function(e) return false to prevent drag
				etHideStatus: "=",//default: false
			},
			link: function(scope, element, attrs){
				element.addClass("etDeviceMap");

				scope.etModel.updateZoomValue = function(){
					if (!scope.etModel.zoomLv)
						scope.etModel.zoomLv = 0;	
					scope.etModel.zoomValue = Math.pow(KEY.scalePerZoom, scope.etModel.zoomLv);	
				}
				scope.etModel.updateZoomValue();
				if (!scope.etModelField)
					scope.etModelField = "markers";	//floorplanIconSize
				if (!scope.etTypeField)
					scope.etTypeField = "applicationType";

				scope.APPLICATION_TYPE_INV = APIKEY.applicationTypeInv;

				scope.imgObj = new Image();
				scope.imgStyle = {};
				scope.imgContainerStyle = {};
				scope.imgObj.onload = function(){
					scope.$apply(function() {
						scope.imgStyle.height = scope.imgObj.height + 'px';
						scope.imgStyle.width = scope.imgObj.width + 'px';
						scope.imgContainerStyle.height = scope.imgStyle.height;
						scope.imgContainerStyle.width = scope.imgStyle.width;
						scope.imgStyle['background-image'] = 'url("' + scope.etImageUrl + '")';
			            //zoom to fit
			            scope.etModel.zoomLv = 0;
			            var maxWidth = element[0].getBoundingClientRect().width;
			            var imgWidth = scope.imgObj.width;
			            while (imgWidth > maxWidth){
			                imgWidth /= KEY.scalePerZoom;
			                scope.etModel.zoomLv--;
			            }
			            scope.etModel.updateZoomValue();
					});
				}
				scope.$watch("etImageUrl", function(n, o){
					scope.imgObj.src = n;
				});
				scope.$watch("etModel.zoomValue", function(n, o){
					scope.imgStyle.transform = "scale(" + n + ")";
					scope.imgContainerStyle.width = scope.imgObj.width * n + 'px';
					scope.imgContainerStyle.height = scope.imgObj.height * n + 'px';
				});
		        scope.etModel.zoomLv = 0;
				scope.etModel.updateZoomValue();

				scope.zoomIn = function(){
					scope.etModel.zoomLv++;
					scope.etModel.updateZoomValue();
				}
				scope.zoomOut = function(){
					scope.etModel.zoomLv--;
					scope.etModel.updateZoomValue();
				}
				scope.toggleDimMode = function(){
					scope.etModel.isDimMode = !scope.etModel.isDimMode;
				}

				scope.dragOpt = {
				    autoScroll: true,
				    distance: 1,
				    filter: ".marker:not(.noDrag)",
				    cursorOffset: { top: 0, left: 0 },
				    hint: function(e) {
				    	var arr = e.css("transform").split(",");
				    	if (arr.length == 6){
					    	arr[3] = scope.etModel.zoomValue;
					    	arr[0] = "matrix(" + scope.etModel.zoomValue;
					        return $('<div class="hint ' + e.attr("class") + '" style="transform: ' + arr.join() + '"></div>');
					    } else {
					    	console.log("don't support this transfrom", arr);
					    }
				    },
				    dragstart: function(e) {
				    	if (!scope.etDraggable || false === scope.etDragStart({$i: e.currentTarget.data("index"), $event: e})){
				    		e.preventDefault();
				    		return;
				    	}
				        e.currentTarget.hide();
				    },
				    dragend: function(e) {
				        e.currentTarget.show();
				    }
				}
				scope.dropOpt = {
				    drop: function(e) {
				    	var i = e.draggable.currentTarget.data("index");
				    	if (i != null){
				    		var d = scope.etModel[scope.etModelField][i];
				    	} else {
				    		console.error("index not found");
				    		return;
				    	}
				        var size = e.draggable.currentTarget.width();
				        var targetOffset = angular.element(e.dropTarget).offset();
				        scope.$apply(function() {
							if (d.location == null)
								d.location = {};
				            d.location.x = (e.pageX - targetOffset.left) / scope.etModel.zoomValue;// - e.offsetX + size / 2;
				            d.location.y = (e.pageY - targetOffset.top) / scope.etModel.zoomValue;// - e.offsetY + size / 2;
				            scope.etModel.selectedMarker = d;
				        });
				        scope.etMarkerDrop({$i: i, $event: e});
				    }
				}

				scope.onDeviceClick = function($index, $event){
					$event.stopPropagation();
					scope.etModel.selectedMarker = scope.etModel[scope.etModelField][$index];
					scope.etMarkerClick({$i: $index, $event: $event});
				}
				scope.onMapClick = function($event){
					scope.etModel.selectedMarker = null;
					if (scope.etMapClick){
						var pos = angular.element($event.currentTarget).offset();
						pos.left = ($event.pageX - pos.left) / scope.etModel.zoomValue;
						pos.top = ($event.pageY - pos.top) / scope.etModel.zoomValue;
						scope.etMapClick({$event: $event, $x: pos.left, $y: pos.top});
						$event.stopPropagation();
					}
				}
				scope.onDeviceHover = function($event){
					var target = angular.element($event.target);
					if (target.hasClass("marker")){
						var i = target.data("index");
						var d = scope.etModel[scope.etModelField][i];
						var screenPos = target.parent().get(0).getBoundingClientRect();
						scope.anchorPos = {
							left: (d.location.x*scope.etModel.zoomValue + screenPos.left + 'px'),
							top: (d.location.y*scope.etModel.zoomValue + screenPos.top + 'px'),
							transform: 'translate(-50%, -50%) scale(' + scope.etModel.zoomValue + ')',
						}

						if (attrs.etMarkerHover)
							scope.etMarkerHover({$i: i, $event: $event});
					}
				}
				scope.onDeviceHoverEnd = function($event){
					if (attrs.etMarkerHoverEnd){
						var target = angular.element($event.target);
						if (target.hasClass("marker"))
							scope.etMarkerHoverEnd({$i: target.data("index"), $event: $event});
					}
				}

				var html = '<div class="tooltipAnchor" ng-style="anchorPos"><div></div></div>'
						+ '<button class="dimBtn" ng-class="{dim: etModel.isDimMode}" ng-click="toggleDimMode()"></button>'
						 + '<div class="zoomControl colCenter">'
						 	+ '<button class="zoomInBtn" ng-click="zoomIn()"></button>'
						 	+ '<button class="zoomOutBtn" ng-click="zoomOut()"></button>'
						 + '</div><div kendo-draggable k-options="dragOpt" class="deviceMap" ng-click="onContainerClick($event)"' + (attrs.etMapScrolled ? ' et-scrolled="etMapScrolled()">' : '>')
						 	+ '<div ng-style="imgContainerStyle">'
							 	+ '<div kendo-drop-target k-options="dropOpt" ng-mouseover="onDeviceHover($event)" ng-mouseout="onDeviceHoverEnd($event)" ng-style="imgStyle">'
							 		+ '<div id="{{m.id}}" data-index="{{$index}}" ng-click="onDeviceClick($index, $event)" ng-show="m.location.x != null" class="marker{{etHideStatus || m.online ? \'\' : \' offline\'}} {{APPLICATION_TYPE_INV[(m[etTypeField])]}} {{etDraggable ? \'\' : \'noDrag\'}} {{m.cssClass}}" '
							 			 + 'ng-repeat="m in etModel[etModelField]" ng-style="{left: m.location.x+\'px\', top: m.location.y+\'px\'}" ></div>'
						 			+ '<div class="dimLayer" ng-class="{dim: etModel.isDimMode}" ng-click="onMapClick($event)"></div>'
					 			+ '</div>'
						 	+ '</div>'
						 + '</div>';
				var elm = $compile(html)(scope);
				element.append(elm);
			}
		}
    }]).directive("etMapCut", ['$compile', 'KEY', 'APIKEY', function($compile, KEY, APIKEY){//no pan, will have y scrollbar
		return {
			restrict: "E",
			scope: {
				etImageUrl: "@",
				etModel: "=",	//need an object for 2-way binding to work, ( return fields: markers.displayClass, return functions: getSelectedArea(callback(blob, selectedMarkers, x, y, w, h)) )
				etMarkerClick: "&",	//function(...., $i, $event)
				etModelField: "@",	//default: markers
				etTypeField: "@",	//default: type
				etHideStatus: "=",	//default: false
				etMargin: "=",		//default: 50
			},
			link: function(scope, element, attrs){
				element.addClass("etMapCut");
		        if (attrs.id) {
		            scope.instanceId = attrs.id;
		        } else {
		            scope.instanceId = "mapCut" + (Math.random() + "").replace("0.", "");
		            element.attr("id", scope.instanceId);
		        }
		        var cutBoxStr = "#" + scope.instanceId + " .cutBox";
				var cutBox, topLayer, bottomLayer, leftLayer, rightLayer;

				if (!scope.etModelField)
					scope.etModelField = "markers";	//floorplanIconSize
				if (!scope.etTypeField)
					scope.etTypeField = "applicationType";

				scope.APPLICATION_TYPE_INV = APIKEY.applicationTypeInv;

				scope.imgObj = new Image();
				scope.imgStyle = {};
				scope.imgContainerStyle = {};

				scope.etModel.getSelectedArea = function(callback){
	        var x1 = (parseFloat(cutBox.getAttribute('data-x')) || 0);
	        var y1 = (parseFloat(cutBox.getAttribute('data-y')) || 0);
	        var x2 = parseFloat(cutBox.style.width);
	        var y2 = parseFloat(cutBox.style.height);
	        var selectedMarkers = scope.etModel[scope.etModelField].filter(function(d){
	        	return d.displayClass === "on";
	        });
					var c = document.createElement('canvas');
          var ctx = c.getContext('2d');
          var margin = scope.etMargin || 0;
          c.width = x2 + margin;
          c.height = y2 + margin;
					ctx.drawImage(scope.imgObj, x1, y1, x2, y2, margin, margin, x2, y2);
					
					c.toBlob(function(blob){
						callback(blob, selectedMarkers, x1, y1, x2, y2);
					});
				}

				scope.updateCutBox = function(w, h, dLeft, dTop){
	        var x = (parseFloat(cutBox.getAttribute('data-x')) || 0);
	        var y = (parseFloat(cutBox.getAttribute('data-y')) || 0);

	        // update the element's style
	        cutBox.style.width = w + 'px';
	        cutBox.style.height = h + 'px';

	        // translate when resizing from top or left edges
	        x += dLeft;
	        y += dTop;
	        cutBox.style.transform = 'translate(' + x + 'px,' + y + 'px)';
	        cutBox.setAttribute('data-x', x);
	        cutBox.setAttribute('data-y', y);

	        topLayer.style.height = y + 'px';
	        topLayer.style.left = x + 'px';
	        topLayer.style.right = 'calc(100% - ' + (x + w) + 'px)';
	        bottomLayer.style.top = h + y + 'px';
	        bottomLayer.style.left = topLayer.style.left;
	        bottomLayer.style.right = topLayer.style.right;
	        leftLayer.style.width = topLayer.style.left;
	        rightLayer.style.left = x + w + 'px';
        }
        scope.selectInsideMarkers = function(){
	        var x1 = (parseFloat(cutBox.getAttribute('data-x')) || 0);
	        var y1 = (parseFloat(cutBox.getAttribute('data-y')) || 0);
	        var x2 = parseFloat(cutBox.style.width) + x1;
	        var y2 = parseFloat(cutBox.style.height) + y1;
	        var markers = scope.etModel[scope.etModelField];
              for (var i=0; i<markers.length; i++){
                  if (markers[i].applicationType === APIKEY.applicationType.gateway) {
                    markers[i].displayClass = "dim";
                  } else {
                    var loc = markers[i].location;
                    if (loc){
                        if (x1 <= loc.x && loc.x <= x2 && y1 <= loc.y && loc.y <= y2){
                            markers[i].displayClass = "on";
                        } else {
                        	markers[i].displayClass = "dim";
                        }
                    }
                  }
              }
        }

				scope.onDeviceClick = function($index, $event){
					$event.stopPropagation();
					var marker = scope.etModel[scope.etModelField][$index];
					if (marker.displayClass !== "dim"){
						marker.displayClass = marker.displayClass === "on" ? "" : "on";
						scope.etMarkerClick({$i: $index, $event: $event});
					}
				}

				scope.imgObj.onload = function(){
					cutBox = $(cutBoxStr).get(0);
		        	topLayer = $("#" + scope.instanceId + " .topOverlay").get(0);
		        	bottomLayer = $("#" + scope.instanceId + " .bottomOverlay").get(0);
		        	leftLayer = $("#" + scope.instanceId + " .leftOverlay").get(0);
		        	rightLayer = $("#" + scope.instanceId + " .rightOverlay").get(0);
		            var maxWidth = Math.min(element[0].getBoundingClientRect().width, scope.imgObj.width);
		            var maxHeight = Math.min(element[0].getBoundingClientRect().height, scope.imgObj.height);
		            var viewMin = Math.min(50, maxWidth, maxHeight);

					//image fit width
					scope.$apply(function() {
						scope.imgStyle.width = scope.imgObj.width + 'px';
						scope.imgStyle.height = scope.imgObj.height + 'px';
						
						scope.imgStyle['background-image'] = 'url("' + scope.etImageUrl + '")';
						scope.imgContainerStyle.height = scope.imgStyle.height;
						scope.imgContainerStyle.width = scope.imgStyle.width;
						scope.imgContainerStyle.position = 'relative';
					});
		        	//init cutBox location
		        	var initialWidth = Math.floor(maxWidth * 0.6);
		        	var initialHeight = Math.floor(maxHeight * 0.6);
		        	var initialLeft = Math.floor(maxWidth * 0.2);
		        	var initialTop = Math.floor(maxHeight * 0.2);

		        	scope.updateCutBox(initialWidth, initialHeight, initialLeft, initialTop);
		        	scope.$apply(scope.selectInsideMarkers);
		        	//resize action
		            interact(cutBoxStr).resizable({
		                edges: { left: true, right: true, top: true, bottom: true },
					    listeners: {
					        move(event) {
						        scope.updateCutBox(event.rect.width, event.rect.height, event.deltaRect.left, event.deltaRect.top);
					        },
					        end(event) {
					        	scope.$apply(scope.selectInsideMarkers);
					        }
					    },
					    modifiers: [
					      // keep the edges inside the parent
					      interact.modifiers.restrictEdges({
					        outer: 'parent'
					      }),
					      // minimum size
					      interact.modifiers.restrictSize({
					        min: { width: viewMin, height: viewMin }
					      })
					    ],
		            });
				}
				scope.$watch("etImageUrl", function(n, o){
					scope.imgObj.src = encodeURI(n);
				});

				scope.$on('$destroy', function() {
					if (interact.isSet(cutBoxStr))
						interact(cutBoxStr).unset();
				});

				var html = '<div class="deviceMap">'
						 	+ '<div ng-style="imgContainerStyle">'
							 	+ '<div ng-style="imgStyle">'
							 		+ '<div data-index="{{$index}}" ng-click="onDeviceClick($index, $event)" ng-show="m.location.x != null" class="marker noDrag{{etHideStatus || m.online ? \'\' : \' offline\'}} {{APPLICATION_TYPE_INV[(m[etTypeField])]}} {{m.displayClass}}" '
							 			 + 'ng-repeat="m in etModel[etModelField]" ng-style="{left: m.location.x+\'px\', top: m.location.y+\'px\'}" ></div>'
					 			+ '</div><div class="cutBox"><div class="bottomHandle"></div><div class="topHandle"></div><div class="leftHandle"></div><div class="rightHandle"></div></div>'
					 			+ '<div class="topOverlay"></div><div class="bottomOverlay"></div><div class="leftOverlay"></div><div class="rightOverlay"></div>'
						 	+ '</div>'
						 + '</div><canvas id="{{instanceId}}Canvas" style="visibility:hidden;"></canvas>';
				var elm = $compile(html)(scope);
				element.append(elm);
			}
		}
	}]).directive("etPanZoom", ['$compile', '$timeout', 'KEY', function($compile, $timeout, KEY){//pan will also trigger click, db click will also trigger click on child element
		return {
			restrict: "E",
			transclude: true,
			scope: {
				etModel: "=",	//( return function: zoom, centerAt)
				etInitialCenter: "=",	//{x,y}: specific point, default: no center
			},
			template: '<div class="zoomControl colCenter">'
						+ '<button class="zoomInBtn" ng-click="zoom(true)"></button>'
						+ '<button class="zoomOutBtn" ng-click="zoom()"></button>'
					+ '</div>'
					+ '<div class="panZoomRoot" ng-transclude></div>',
			link: function(scope, element, attrs){
				element.addClass("etPanZoom");

				var elm = element.get(0);

				scope.zoom = function(isZoomIn) {
		            scope.pz.smoothZoom(elm.offsetWidth/2, elm.offsetHeight/2, (isZoomIn ? KEY.scalePerZoom : 1/KEY.scalePerZoom));
		        }

		        scope.centerAt = function(loc){
		        	scope.pz.moveTo(elm.offsetWidth/2 - loc.x*scope.pz.getTransform().scale, elm.offsetHeight/2 - loc.y*scope.pz.getTransform().scale);
		        }

		        if (scope.etModel){
		        	scope.etModel.zoom = scope.zoom;
		        	scope.etModel.centerAt = scope.centerAt;
		        }

				scope.pz = panzoom(element.children('.panZoomRoot').get(0), {
                    bounds: true,
                    boundsPadding: 0.1,
                    maxZoom: KEY.zoomMax,
                    minZoom: KEY.zoomMin,
                    zoomDoubleClickSpeed: 1, //1 = disable db click zoom
                });

                if (scope.etInitialCenter && typeof scope.etInitialCenter === 'object'){
	                $timeout(function(){
	                	scope.centerAt(scope.etInitialCenter);
		            });
	            }

				scope.$on('$destroy', function(){
					scope.pz.dispose();
				});

			}
		}
	}]).directive("etTable", ['KEY', function(KEY){
		return {
			restrict: "E",
			link: function(scope, element, attrs){
				element.addClass("etTable");
				$(".etTable .etTableHead").css({'padding-right': KEY.scrollBarWidth + 'px'});
			}
		}
	}]).directive("etStatusControls", ['$compile', function($compile){
		return {
			restrict: "E",
			scope: {
				etNode: "=",			//node object to be controlled
				etShowLoading: "=?",	//show loading icon or not, default: true
				etLoadingField: "=?",	//default name: isLoading, can be array
				etDisconnectFunc: "&",	//function(..., $node)
				etDisconnectField: "@",	//default name: offline_count
			},
			link: function(scope, element, attrs){
				element.addClass("etStatusControls");

				if (attrs.etDisconnectFunc){
					if (!scope.etDisconnectField)
						scope.etDisconnectField = "offline_count";
					scope.onClickDisconnect = function(){
						scope.etDisconnectFunc({$node: scope.etNode});
					}
					element.prepend($compile('<button ng-if="etNode[etDisconnectField]" class="errorBtn" ng-click="onClickDisconnect()"></button>')(scope));
				}

				if (scope.etShowLoading === undefined)
					scope.etShowLoading = true;
				if (scope.etShowLoading){
					if (Array.isArray(scope.etLoadingField)){
						if (scope.etLoadingField.length == 0)
							scope.etLoadingField = ["isLoading"];
						element.prepend($compile('<div class="partialLoadingIcon" ng-show="etNode.' + scope.etLoadingField.join(' || etNode.') + '"></div>')(scope));
					} else {
						if (!scope.etLoadingField)
							scope.etLoadingField = "isLoading";
						element.prepend($compile('<div class="partialLoadingIcon" ng-show="etNode[etLoadingField]"></div>')(scope));
					}
				}
			}
		}
    }]).directive("etTemperatureBtn", ['$compile', 'APIKEY', function($compile, APIKEY){
		return {
			restrict: "E",
			scope: {
				etNode: "=",			//node object to be controlled
				etControlFunc: "&",		//function(..., $node, $action)
				etLongBtn: "=",			//if false, button label is removed, default: false
				etColumnDisplay: "=",	//if true, align button in a column, default: false
			},
			link: function(scope, element, attrs){
				element.addClass("etTemperatureBtn");

				scope.onClickTemperature = function(cooler){
					scope.etControlFunc({$node: scope.etNode, $action: (cooler ? APIKEY.action.cooler : APIKEY.action.warmer)});
				}

				var clsSuffix = scope.etLongBtn ? '' : 'Only';
				var warmLbl = scope.etLongBtn ? 'button.warmer' : '';
				var coolLbl = scope.etLongBtn ? 'button.cooler' : '';
				var html = '<button ng-disabled="etNode.warming || etNode.cooling || etNode.isCooling || etNode.isWarming" class="normalBtn border round lightColor warmer' + clsSuffix + '" ng-click="onClickTemperature(false)" translate>' + warmLbl + '</button>'
						 + '<button ng-disabled="etNode.warming || etNode.cooling || etNode.isCooling || etNode.isWarming" class="normalBtn border round lightColor cooler' + clsSuffix + '" ng-click="onClickTemperature(true)" translate>' + coolLbl + '</button>';
				if (!scope.etColumnDisplay)
					html = '<div class="rowStart">' + html + '</div>';
				html += '<div ng-show="etNode.warming || etNode.cooling" translate>label.adjustingTemperature</div>';

				var elm = $compile(html)(scope);
				element.append(elm);
			}
		}
    }]);
    
})();
