(function() {
    'use strict';

    angular.module('EntrakV5').config(routerConfig);

    function routerConfig($stateProvider, $urlRouterProvider) {
        console.log('config');
        $stateProvider.state('landing', {
            url: '/landing?m?it?rt',
            controller: 'landingController'
        }).state('hub', {
            url: '/hub',
            template: require('./hub/hub.html'),
            controller: 'hubController'
        // }).state('slGateway', {
        //     url: '/slGateway',
        //     template: require('./slGateway/slGateway.html'),
        //     controller: 'slGatewayController'
        }).state('city', {
            url: '/city',
            template: require('./city/city.html'),
            controller: 'cityController'
        }).state('distributor', {
            url: '/distributor',
            template: require('./distributor/distributor.html'),
            controller: 'distributorController'
        }).state('distributorDetail', {
            url: '/distributorDetail/:distributorId',
            template: require('./distributorDetail/distributorDetail.html'),
            controller: 'distributorDetailController'
        }).state('landlordProfile', {
            url: '/landlordProfile',
            template: require('./landlordProfile/landlordProfile.html'),
            controller: 'landlordProfileController'
        }).state('tenant', {
            url: '/tenant?tenantId',
            template: require('./tenant/tenant.html'),
            controller: 'tenantController'
        }).state('tenantAdmin', {
            url: '/tenantAdmin/:tenantId',
            template: require('./tenantAdmin/tenantAdmin.html'),
            controller: 'tenantAdminController'
        }).state('building', {
            url: '/building',
            template: require('./building/building.html'),
            controller: 'buildingController'
        }).state('timetable', {
            url: '/timetable',
            template: require('./timetable/timetable.html'),
            controller: 'timetableController'
        }).state('floor', {
            url: '/floor/:tenantId/:floorId?gatewayId',
            template: require('./floor/floor.html'),
            controller: 'floorController'
        }).state('gateway', {
            url: '/gateway/:tenantId/:floorId',
            template: require('./gateway/gateway.html'),
            controller: 'gatewayController'
        }).state('allGateway', {
            url: '/allGateway',
            template: require('./gateway/gateway.html'),
            controller: 'gatewayController'
        }).state('ranking', {
            url: '/ranking',
            template: require('./ranking/ranking.html'),
            controller: 'rankingController'
        }).state('device', {
            url: '/device?serial?id',
            template: require('./device/device.html'),
            controller: 'deviceController'
        // }).state('gatewayDevice', {
        //     url: '/gatewayDevice/:tenantId/:floorId/:gatewayId',
        //     template: require('./gatewayDevice/gatewayDevice.html'),
        //     controller: 'gatewayDeviceController'
        // }).state('deviceDetail', {
        //     url: '/deviceDetail/:tenantId/:floorId/:deviceId/:gatewayId?',
        //     template: require('./deviceDetail/deviceDetail.html'),
        //     controller: 'deviceDetailController'
        }).state('meter', {
            url: '/meter',
            template: require('./meter/meter.html'),
            controller: 'meterController'
        }).state('occupiedArea', {
            url: '/occupiedArea',
            template: require('./occupiedArea/occupiedArea.html'),
            controller: 'occupiedAreaController'
        }).state('deviceLocation', {
            url: '/deviceLocation/:tenantId/:floorId',
            template: require('./deviceLocation/deviceLocation.html'),
            controller: 'deviceLocationController'
        });

        $urlRouterProvider.otherwise('/tenant');
    }

})();
